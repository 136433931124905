<template>
  <div class="article-ranking-container">
    <el-card class="header">
      <div class="dynamic-box">
        <span class="title">{{ $t('msg.track.dynamicTitle') }}</span>
      </div>
    </el-card>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('msg.track.containerInfo')" name="ContainerInfo">
          <el-table ref="tableRef" :data="tableData" border>
            <el-table-column prop="id" label="id" width="60"></el-table-column>
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" ></el-table-column>
            <el-table-column prop="mbl" :label="$t('msg.track.mbl')" ></el-table-column>
            <el-table-column prop="pod" :label="$t('msg.track.pod')" ></el-table-column>
            <el-table-column prop="eta" :label="$t('msg.track.eta')" ></el-table-column>
            <el-table-column prop="ata" :label="$t('msg.track.ata')" ></el-table-column>
            <el-table-column prop="firmsCode" :label="$t('msg.track.firmsCode')" ></el-table-column>
            <el-table-column prop="dischargeTime" :label="$t('msg.track.dischargeTime')" ></el-table-column>
            <el-table-column prop="holdStatus" :label="$t('msg.track.holdStatus')" ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.terminalInfo')" name="TerminalInfo">
          <el-table ref="tableRef" :data="tableData" border>
            <el-table-column prop="id" label="id" width="60"></el-table-column>
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" ></el-table-column>
            <el-table-column prop="pickupAva" :label="$t('msg.track.pickupAva')" ></el-table-column>
            <el-table-column prop="appointTime" :label="$t('msg.track.appointTime')" ></el-table-column>
            <el-table-column prop="lfd" :label="$t('msg.track.lfd')" ></el-table-column>
            <el-table-column prop="locationAtTerminal" :label="$t('msg.track.locationAtTerminal')" ></el-table-column>
            <el-table-column prop="outGateTime" :label="$t('msg.track.outGateTime')" ></el-table-column>
            <el-table-column prop="emptyReturnAppointTime" :label="$t('msg.track.emptyReturnAppointTime')" ></el-table-column>
            <el-table-column prop="emptyReturnTime" :label="$t('msg.track.emptyReturnTime')" ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.drayServiceInfo')" name="DrayServiceInfo">
          <el-table ref="tableRef" :data="tableData" border>
            <el-table-column prop="id" label="id" width="60"></el-table-column>
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" ></el-table-column>
            <el-table-column prop="drayCompany" :label="$t('msg.track.drayCompany')" ></el-table-column>
            <el-table-column prop="oswCompany" :label="$t('msg.track.oswCompany')" ></el-table-column>
            <el-table-column prop="ctnrSize" :label="$t('msg.track.ctnrSize')" ></el-table-column>
            <el-table-column prop="weight" :label="$t('msg.track.weight')" ></el-table-column>
            <el-table-column prop="termFeeTotal" :label="$t('msg.track.termFeeTotal')" ></el-table-column>
            <el-table-column prop="relationId" :label="$t('msg.track.relationId')" ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onActivated } from 'vue'
import { getTerminalInfoList } from '@/api/track'
import { watchSwitchLang } from '@/utils/i18n'

import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)

const activeName = ref('ContainerInfo')
// 获取数据的方法
const getListData = async () => {
  const result = await getTerminalInfoList({
    page: page.value,
    size: size.value
  })
  tableData.value = result.list
  total.value = result.total
}
// getListData()
// 监听语言切换
watchSwitchLang(getListData)
// 处理数据不重新加载的问题
onActivated(getListData)

/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

/**
 * 查看按钮点击事件
 */
// eslint-disable-next-line no-unused-vars
const router = useRouter()

// 删除用户
// eslint-disable-next-line no-unused-vars
const i18n = useI18n()

</script>

<style lang="scss" scoped>
.article-ranking-container {
  .header {
    margin-bottom: 20px;
    .dynamic-box {
      display: flex;
      align-items: center;
      .title {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

::v-deep .sortable-ghost {
  opacity: 0.6;
  color: #fff !important;
  background: #304156 !important;
}
</style>
